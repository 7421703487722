import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconButtonModule } from '@features/ui/components/icon-button';

import {
  IncomingCallDialogComponent,
  NotificationsComponent,
} from './components';

@NgModule({
  declarations: [IncomingCallDialogComponent, NotificationsComponent],
  imports: [CommonModule, IconButtonModule],
  exports: [NotificationsComponent],
  providers: [],
})
export class NotificationsModule {}
