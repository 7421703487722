import { ApplicationConfig, isDevMode } from '@angular/core';
import { provideRouter, withViewTransitions } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideServiceWorker } from '@angular/service-worker';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';

import { environment } from '@environment/environment';

import { provideApi } from '@features/api';
import { httpTokenInterceptor, provideAuth } from '@features/auth';
import { httpDeviceInterceptor } from '@features/device';
import { httpErrorInterceptor } from '@features/errors';
import { provideLegal } from '@features/legal';

import { routes } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    provideApi(environment.api_url, {
      interceptors: [
        httpErrorInterceptor,
        httpTokenInterceptor,
        httpDeviceInterceptor,
      ],
    }),
    provideAuth(),
    provideRouter(routes, withViewTransitions()),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideMessaging(() => getMessaging()),
    provideLegal(),
    provideAnimations(),
  ],
};
