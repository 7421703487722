import { Routes } from '@angular/router';
import { authGuard } from '@features/auth';

export const routes: Routes = [
  {
    path: '',
    title: 'Reakt360',
    loadChildren: () => import('@features/home').then((m) => m.HomeModule),
    canActivate: [authGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'call',
    title: 'Call',
    loadChildren: () => import('@features/call').then((m) => m.CallModule),
    canActivate: [authGuard],
    runGuardsAndResolvers: 'always',
  },
];
