<div class="flex flex-col gap-8 items-center justify-center">
  <h3 class="font-normal font-display text-2xl">Incoming Call</h3>

  <p class="font-normal text-base text-gray-500">{{ data.caller }}</p>

  <div class="flex flex-row gap-16 items-center justify-center">
    <button app-icon-button (click)="data.answer()" title="Accept Call">
      <img src="assets/images/call-accepted.svg" alt="Accept Call" />
    </button>

    <button app-icon-button (click)="data.reject()" title="Reject Call">
      <img src="assets/images/call-rejected.svg" alt="Reject Call" />
    </button>
  </div>
</div>