import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { provideRouter } from '@angular/router';

import {
  PrivacyPolicyPageComponent,
  TermsAndConditionsPageComponent,
} from './pages';
import { routes } from './legal.routes';

@NgModule({
  declarations: [TermsAndConditionsPageComponent, PrivacyPolicyPageComponent],
  imports: [CommonModule],
  exports: [],
  providers: [provideRouter(routes)],
})
export class LegalModule {}
