import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NotificationsModule } from '@features/notifications';
import { SnackBarModule } from '@features/ui/components/snack-bar';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterModule, SnackBarModule, NotificationsModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent {}
