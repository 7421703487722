import { Injectable, inject } from '@angular/core';
import {
  MessagePayload,
  Messaging,
  getToken,
  onMessage,
  deleteToken,
  NextFn,
  Unsubscribe,
} from '@angular/fire/messaging';
import {
  EMPTY,
  Observable,
  Observer,
  catchError,
  exhaustMap,
  from,
  of,
  switchMap,
  tap,
} from 'rxjs';

import { ApiService } from '@features/api';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  protected api = inject(ApiService);
  protected messaging = inject(Messaging);
  #unsubscribe?: Unsubscribe;

  register(
    nextOrObserver: NextFn<MessagePayload> | Observer<MessagePayload>
  ): Observable<void> {
    if (!('Notification' in window)) {
      console.log('Notifications not supported in this browser');
      return EMPTY;
    }

    return this.registerServiceWorker().pipe(
      switchMap((registration) => {
        return this.requestPermission().pipe(
          switchMap((permission) => {
            if (permission === 'granted') {
              this.#unsubscribe = onMessage(this.messaging, nextOrObserver);
              return this.registerToken(registration);
            }

            return EMPTY;
          }),
          catchError((error) => {
            console.log('Error requesting permission');
            console.error(error);
            return EMPTY;
          })
        );
      })
    );
  }

  unregister(): Observable<void> {
    return this.token().pipe(
      exhaustMap((token) => this.unsubscribe(token)),
      switchMap(() => from(deleteToken(this.messaging))),
      switchMap(() => EMPTY)
    );
  }

  private requestPermission() {
    if (Notification.permission === 'granted') {
      // Already granted.
      return of(Notification.permission);
    } else if (Notification.permission !== 'denied') {
      // Not denied, request permission.
      return from(Notification.requestPermission());
    }

    // Denied.
    return of(Notification.permission);
  }

  private registerServiceWorker() {
    return from(
      navigator.serviceWorker.register('/firebase-messaging-sw.js')
    ).pipe(switchMap(() => from(navigator.serviceWorker.ready)));
  }

  private registerToken(registration?: ServiceWorkerRegistration) {
    return this.token(registration).pipe(
      exhaustMap((token) => this.subscribe(token))
    );
  }

  protected token(registration?: ServiceWorkerRegistration) {
    return from(
      getToken(this.messaging, { serviceWorkerRegistration: registration })
    );
  }

  protected subscribe(token: string) {
    return this.api.post<void>('/user/notifications', {
      device_token: token,
      device_type: 'web',
      os_version: navigator.userAgent,
    });
  }

  protected unsubscribe(token: string) {
    return this.api
      .delete<void>(`/user/notifications/${token}`)
      .pipe(tap(() => this.#unsubscribe?.()));
  }
}
