import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, inject, OnInit } from '@angular/core';

export interface IncomingCallData {
  caller?: string;
  answer: () => void;
  reject: () => void;
}

@Component({
  selector: 'app-incoming-call-dialog',
  templateUrl: './incoming-call-dialog.component.html',
  styleUrls: ['./incoming-call-dialog.component.css'],
})
export class IncomingCallDialogComponent implements OnInit {
  protected data = inject<IncomingCallData>(DIALOG_DATA);

  constructor() {}

  ngOnInit(): void {}
}
