import { Route } from '@angular/router';
import {
  PrivacyPolicyPageComponent,
  TermsAndConditionsPageComponent,
} from './pages';

export const routes: Route[] = [
  {
    path: 'terms',
    title: 'Terms and Conditions',
    component: TermsAndConditionsPageComponent,
  },
  {
    path: 'privacy',
    title: 'Privacy Policy',
    component: PrivacyPolicyPageComponent,
  },
];
